@import "../../../../Shared/colors";
.header {
  width: 100%;
  height: 832px;
  margin-bottom: 77px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 832" preserveAspectRatio="none" fill="none"><path d="M0 0H1440V514.016C1121.43 1212.96 357.03 488.52 0 832V0Z" fill="url(%23paint0_linear_1098_6454)"/><defs><linearGradient id="paint0_linear_1098_6454" x1="720" y1="0" x2="720" y2="832" gradientUnits="userSpaceOnUse"><stop stop-color="%23FFEE96"/><stop offset="1" stop-color="%23FFEE96" stop-opacity="0.44"/></linearGradient></defs></svg>');
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  @media screen and (max-width: 1024px) {
    height: 308px;
    background-image: url('data:image/svg+xml;utf8,<svg width="360" height="308" viewBox="0 0 360 308" preserveAspectRatio="none" fill="none" xmlns="http://www.w3.org/2000/svg" ><path d="M0 0H360C360 0 382.5 246 235.5 259C88.5 272 94.723 215.95 0 308V0Z" fill="url(%23paint0_linear_1057_6488)"/><defs><linearGradient id="paint0_linear_1057_6488" x1="180.618" y1="0" x2="180.618" y2="308" gradientUnits="userSpaceOnUse"><stop stop-color="%23FFEE96"/><stop offset="1" stop-color="%23FFEE96" stop-opacity="0"/></linearGradient></defs></svg>');
    margin-bottom: 68px;
  }
  .header__wrapper {
    max-width: 904px;
    width: 100%;
    margin: 0 auto;
    padding-top: 80px;
    @media screen and (max-width: 1024px) {
      padding-top: 36px;
    }
    .header__title {
      padding-left: 129px;
      margin-bottom: 50px;

      @media screen and (max-width: 1024px) {
        padding-left: 0px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 62px;
      }
      h1 {
        font: 700 100px/100px "Anonymous Pro";
        color: $main-text;
        margin-bottom: 8px;
        @media screen and (max-width: 1024px) {
          font: 700 40px/40px "Anonymous Pro";
        }
      }
      span {
        letter-spacing: -5.6px;
        position: relative;
        @media screen and (max-width: 1024px) {
          letter-spacing: -0px;
        }
        &::after {
          content: "";
          position: absolute;
          bottom: 3px;
          left: 0;
          width: 197px;
          height: 5px;
          background-color: $title_cross;
          @media screen and (max-width: 1024px) {
            width: 86px;
            height: 3px;
          }
        }
      }
      h2 {
        font: 400 30px/36px "Inter";
        color: $main-text;
        letter-spacing: 1px;
        margin-left: -5px;
        @media screen and (max-width: 1024px) {
          display: none;
        }
      }
    }
    .header__image_wrapper {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      position: relative;
      .header__images-left {
        width: 100%;
        height: 100%;
        position: absolute;
        .left__tag {
          position: absolute;
          font: 700 32px/32px "Anonymous Pro";
          color: $main-text;
          @media screen and (max-width: 1024px) {
            font: 700 16px/16px "Anonymous Pro";
          }
          &:nth-of-type(1) {
            top: -8%;
            left: -21%;
            @media screen and (max-width: 1024px) {
              top: -18%;
              left: 25%;
            }
          }
          &:nth-of-type(2) {
            top: 4%;
            left: -7%;
            @media screen and (max-width: 1024px) {
              display: none;
            }
          }
          &:nth-of-type(3) {
            top: 18%;
            left: -22%;
            @media screen and (max-width: 1024px) {
              top: 2%;
              left: 7%;
            }
          }
          &:nth-of-type(4) {
            top: 36%;
            left: -14%;
            @media screen and (max-width: 1024px) {
              top: -11%;
              left: 11%;
            }
          }
          &:nth-of-type(5) {
            top: 49%;
            left: -24%;
            @media screen and (max-width: 1024px) {
              top: -24%;
              left: 7%;
            }
          }
        }
      }
      .header__image {
        width: 100%;
        height: 611.95px;
        background-image: url("../../../../assets/svg/main-image.svg");
        background-repeat: no-repeat;
        background-position: left 36% center;
        position: relative;
        z-index: 10;
        @media screen and (max-width: 1024px) {
          height: 263.52px;
          background-image: url("../../../../assets/svg/main-image-mobile.svg");
          background-position: left 50% top 50%;
        }
      }
      .header__images-right {
        width: 100%;
        height: 100%;
        position: absolute;
        .right__tag {
          position: absolute;
          font: 700 32px/32px "Anonymous Pro";
          color: $main-text;
          background-color: #e9ebf8;
          padding: 4px 7px 4px 7px;
          border-radius: 10px;
          letter-spacing: 1px;
          z-index: 10;
          @media screen and (max-width: 1024px) {
            font: 700 16px/16px "Anonymous Pro";
          }
          &:nth-of-type(1) {
            top: -18%;
            right: -5%;
            @media screen and (max-width: 1024px) {
              display: none;
            }
          }
          &:nth-of-type(2) {
            top: -17%;
            right: -21%;
            @media screen and (max-width: 1024px) {
              top: -7%;
              right: 48%;
            }
          }
          &:nth-of-type(3) {
            top: -11.5%;
            right: 15%;
            @media screen and (max-width: 1024px) {
              top: -23%;
              right: 3%;
            }
          }
          &:nth-of-type(4) {
            top: 0%;
            right: 7%;
            @media screen and (max-width: 1024px) {
              top: 3.5%;
              right: 4.5%;
            }
          }
          &:nth-of-type(5) {
            top: 1%;
            right: -26%;
            @media screen and (max-width: 1024px) {
              top: -22.5%;
              right: 27.5%;
            }
          }
          &:nth-of-type(6) {
            top: 15%;
            right: -15%;

            @media screen and (max-width: 1024px) {
              display: none;
            }
          }
          &:nth-of-type(7) {
            top: 26%;
            right: -22%;
            @media screen and (max-width: 1024px) {
              top: -10.5%;
              right: 7.5%;
            }
          }
          &:nth-of-type(8) {
            top: 29.5%;
            right: 8.5%;
            @media screen and (max-width: 1024px) {
              top: 36.5%;
              right: -8.5%;
              display: none;
            }
          }
          &:nth-of-type(9) {
            top: 36.5%;
            right: -8.5%;
            @media screen and (max-width: 1024px) {
              top: -10.5%;
              right: 33.5%;
            }
          }
          &:nth-of-type(10) {
            top: 48.5%;
            right: -6.5%;
            @media screen and (max-width: 1024px) {
              display: none;
            }
          }
        }
      }
    }
  }
}
