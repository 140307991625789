@import "../../../../Shared/colors";

.button {
  width: 580px;
  height: 80px;
  border-width: 7px 0px 0px 9px;
  border-style: solid;
  border-color: $main-text;
  border-radius: 24px;
  font: 700 30px/36px "Anonymous Pro";
  color: $main-text;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  @media screen and (max-width: 1024px) {
    width: 280px;
    height: 53px;
    font: 700 18px/21.6px "Anonymous Pro";
    border-width: 5px 0px 0px 7px;
    border-radius: 10px;
  }
  &:active {
    border-width: 3px 0px 0px 5px;
  }
}
