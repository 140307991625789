@import "../../Shared/colors";

.footer {
  width: 100%;
  height: 214px;
  background-color: #252323;
  @media screen and (max-width: 1024px) {
    height: 133px;
  }
  .footer__wrapper {
    max-width: 904px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 37px 0;
    row-gap: 8px;
    @media screen and (max-width: 1024px) {
      padding-top: 20px;
      padding-left: 24px;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      // margin-bottom: 36px;
      row-gap: 4px;
    }
    a,
    h3 {
      font: 400 24px/29px "Inter";
      color: $main-background;
      text-align: center;
      letter-spacing: 0.7px;
      @media screen and (max-width: 1024px) {
        font: 400 14px/16.8px "Inter";
      }
    }
  }
}
