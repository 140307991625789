@import "../../../../Shared/colors";

.second-section {
  width: 100%;
  height: 751.49px;
  margin-bottom: 340px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="1440" height="752" viewBox="0 0 1440 752" fill="none" preserveAspectRatio="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M829.873 56.7527C1063.08 111.194 1288.21 163.75 1440 3.13901V680.294C840.726 465.013 404.896 599.601 0 751.494L0 350.208C183.315 -94.1861 514 -16.9878 829.873 56.7527Z" fill="url(%23paint0_linear_1452_5823)"/><defs><linearGradient id="paint0_linear_1452_5823" x1="719.769" y1="0" x2="719.769" y2="836" gradientUnits="userSpaceOnUse"><stop stop-color="%233BEEB8"/><stop offset="1" stop-color="%231D9FFE" stop-opacity="0.28"/></linearGradient></defs></svg>');
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  @media screen and (max-width: 1024px) {
    margin-bottom: 435px;
    height: 443px;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="360" height="443" viewBox="0 0 360 443" fill="none" preserveAspectRatio="none"><path d="M0 57.0227C102.647 -23.3629 231.567 -3.91876 360 25.7843V443C234.172 414.593 113.543 414.911 0 434.472L0 57.0227Z" fill="url(%23paint0_linear_1129_6135)"/><defs><linearGradient id="paint0_linear_1129_6135" x1="338.195" y1="0" x2="338.195" y2="548.197" gradientUnits="userSpaceOnUse"><stop stop-color="%233BEEB8"/><stop offset="1" stop-color="%231D9FFE" stop-opacity="0.28"/></linearGradient></defs></svg>');
  }

  .second-section_wrapper {
    max-width: 904px;
    width: 100%;
    margin: 0 auto;
    padding-top: 170px;
    @media screen and (max-width: 1024px) {
      padding-top: 62px;
      padding-left: 22px;
      padding-right: 22px;
    }

    h2 {
      font: 700 50px/50px "Anonymous Pro";
      color: $main-text-white;
      margin-bottom: 40px;
      @media screen and (max-width: 1024px) {
        font: 700 22px/22px "Anonymous Pro";
        margin-bottom: 25px;
      }
      &:nth-of-type(2) {
        color: $main-text;
        margin-bottom: 30px;
        @media screen and (max-width: 1024px) {
          display: none;
        }
      }
    }
    h3 {
      font: 700 30px/36px "Anonymous Pro";
      color: $main-text;
      letter-spacing: 1px;
      margin-bottom: 25px;
      @media screen and (max-width: 1024px) {
        font: 700 24px/24px "Anonymous Pro";
      }
    }
    p {
      font: 700 24px/29px "Anonymous Pro";
      color: $main-text;
      letter-spacing: 0.75px;
      margin-bottom: 160px;
      position: relative;
      @media screen and (max-width: 1024px) {
        font: 700 16px/18px "Anonymous Pro";
        margin-bottom: 29%;
      }
    }
  }
}
