@import "../../../../Shared/colors";

.fifth-section {
  width: 100%;
  .fifth-section__wrapper {
    max-width: 904px;
    width: 100%;
    margin: 0 auto;
    padding-top: 125px;
    margin-bottom: 145px;

    @media screen and (max-width: 1024px) {
      padding-top: 11px;
      padding-left: 22px;
      padding-right: 22px;
      margin-bottom: 45px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }

    .fifth-section__text {
      width: 100%;
      margin-bottom: 56px;
      @media screen and (max-width: 1024px) {
        margin-bottom: 30px;
      }

      h2 {
        font: 400 60px/72px "Inter";
        font-family: "Inter", sans-serif;
        color: $main-text;
        letter-spacing: 1.7px;
        margin-bottom: 42px;
        @media screen and (max-width: 1024px) {
          font: 400 24px/28.8px "Inter";
          margin-bottom: 8px;
        }
        span {
          font: 700 60px/72px "Inter";
          position: relative;
          @media screen and (max-width: 1024px) {
            font: 700 24px/28.8px "Inter";
          }
          &::after {
            content: "";
            position: absolute;
            bottom: 3px;
            right: 0;
            width: 128px;
            height: 5px;
            background-color: $title_cross;
            @media screen and (max-width: 1024px) {
              width: 51px;
              height: 2px;
              bottom: 0px;
              right: 2px;
            }
          }
        }
      }
      p {
        font-family: "Inter", sans-serif;
        font: 400 30px/36px "Inter";
        letter-spacing: 1px;
        @media screen and (max-width: 1024px) {
          font: 400 16px/19.2px "Inter";
          letter-spacing: 0.5px;
        }
      }
    }
    .fifth-section__form {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      row-gap: 35px;
      position: relative;
      @media screen and (max-width: 1024px) {
        row-gap: 23px;
      }

      .form__inputs {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        @media screen and (max-width: 1024px) {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          row-gap: 22px;
          padding-left: 18px;
        }
        input {
          width: 580px;
          height: 80px;
          border: 2px solid $main-text;
          border-radius: 24px;
          text-align: center;
          font: 400 28px/34px "Inter";
          color: $main-text;
          @media screen and (max-width: 1024px) {
            width: 280px;
            height: 49px;
            border-radius: 10px;
            border-width: 1px;
            font: 400 16px/19.2px "Inter";
          }
        }
        button {
          width: 285px;
          height: 80px;
          border-width: 6px 0px 0px 9px;
          border-style: solid;
          border-color: $main-text;
          border-radius: 24px;
          background-color: $button-submit;
          font: 400 30px/36px "Inter";
          color: $main-background;

          @media screen and (max-width: 1024px) {
            width: 280px;
            height: 50px;
            font: 700 18px/21.6px "Anonymous Pro";
            border-width: 7px 0px 0px 7px;
            border-radius: 10px;
            color: #f2f2f2;
          }
          &:active {
            border-width: 3px 0px 0px 5px;
            background-color: #eb636e;
          }
        }
      }
      .error-message {
        font: 700 18px/21px "Anonymous Pro";
        color: $form-error;
        position: absolute;
        bottom: -27.5%;
        left: 6.5%;
        right: 0;
        @media screen and (max-width: 1024px) {
          font: 700 13px/16px "Anonymous Pro";
          width: 204px;
          bottom: -21.5%;
          left: 16.5%;
        }
      }
    }
    .form__aprove_wrapper {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      -webkit-column-gap: 16px;
      -moz-column-gap: 16px;
      column-gap: 16px;
      @media screen and (max-width: 1024px) {
        padding-left: 22px;
      }
      input {
        display: none;
      }
      label {
        font: 700 24px/29px "Anonymous Pro";
        color: $main-text;
        position: relative;
        letter-spacing: 0.7px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-column-gap: 16px;
        -moz-column-gap: 16px;
        column-gap: 16px;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        @media screen and (max-width: 1024px) {
          font: 700 16px/19px "Anonymous Pro";
        }
        &::before {
          content: "";
          -webkit-appearance: none;
          background-color: transparent;
          border: 4px solid $main-text;
          padding: 10px;
          display: inline-block;
          position: relative;
          vertical-align: middle;
          cursor: pointer;
          @media screen and (max-width: 1024px) {
            border: 2px solid #434343;
            padding: 5px;
          }
        }
      }
      input:checked + label:after {
        content: url("../../../../assets/svg/check-blue.svg");
        display: block;
        position: absolute;
        top: 3px;
        left: 4px;
        width: 22.63px;
        height: 16.97px;
        @media screen and (max-width: 1024px) {
          content: url("../../../../assets/svg/check-blue-mobile.svg");
          top: 0px;
          left: 1px;
          width: 13px;
          height: 10px;
        }
      }
      p {
        white-space: nowrap;
      }
      span,
      a {
        font: 700 24px/29px "Anonymous Pro";
        color: #0094ff;
        border-bottom: 2px solid #0094ff;
        cursor: pointer;
        @media screen and (max-width: 1024px) {
          font: 700 16px/19px "Anonymous Pro";
          white-space: nowrap;
        }
        @media screen and (max-width: 512px) {
          margin-left: 30px;
        }
      }
    }
    .form__aprove_wrapper.error label::before {
      border-color: #fc2b3b;
    }
  }
}

.fifthSection__modal {
  width: 772px;
  height: 490px;
  background-color: $main-background;
  @media screen and (max-width: 1024px) {
    width: 330px;
    height: 335px;
  }
  .fifthSection__modal_wrapper {
    padding: 70px 70px 60px 70px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    @media screen and (max-width: 1024px) {
      padding: 45px 35px;
    }
    p {
      font: 700 30px/36px "Anonymous Pro";
      color: $main-text;
      @media screen and (max-width: 1024px) {
        font: 700 16px/19.2px "Anonymous Pro";
      }
      &:last-of-type {
        margin-top: 40px;
        margin-bottom: 60px;
      }
    }
    button {
      width: 285px;
      height: 80px;
      border-width: 6px 0px 0px 9px;
      border-style: solid;
      border-color: $main-text;
      border-radius: 24px;
      background-color: $button-submit;
      font: 400 30px/36px "Inter";
      color: $main-background;
      margin: 0 auto;
      @media screen and (max-width: 1024px) {
        width: 260px;
        height: 50px;
        font: 400 18px/21.6px "Inter";
        border-width: 5px 0px 0px 7px;
        border-radius: 10px;
      }
      &:active {
        border-width: 3px 0px 0px 5px;
        background-color: #eb636e;
      }
    }
  }
}
