@import "../../../../Shared/colors";

.modal {
  width: 100vw;
  height: 100vh;
  background-color: rgb(30, 30, 30, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 10;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  overflow: auto;
  .modal-wrapper {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: $main-text;
    border-radius: 10px;
    position: relative;
    z-index: 10;
    max-height: calc(100vh - 20px);
    overflow-y: auto;
    .modal-close {
      position: absolute;
      right: 34px;
      top: 34px;
      width: 34px;
      height: 34px;
      border: 0;
      background-color: unset;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      cursor: pointer;
      @media screen and (max-width: 1024px) {
        width: 14px;
        height: 14px;
      }
      span {
        position: absolute;
        border: 3px solid $main-text;
        width: 34px;
        background-color: $main-text;
        @media screen and (max-width: 1024px) {
          width: 14px;
          border-width: 1px;
        }
        &:first-child {
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
        }
        &:last-child {
          -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }
      }
    }
    .modal-content {
      width: 100%;
      height: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: distribute;
      justify-content: space-around;
    }
  }
}

.active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  pointer-events: all;
}
