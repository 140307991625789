@import "../../../../Shared/colors";

.third-section {
  width: 100%;
  height: 1277px;
  margin-bottom: 95px;
  background-image: url('data:image/svg+xml;utf8,<svg width="1440" height="1277" viewBox="0 0 1440 1277" fill="none" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 1277C225.5 1051 895.5 969.943 1440 1106.93V93.9114C1289.9 127.631 1110.97 91.8045 927.67 55.1007C577.767 -14.9611 211.893 -88.2211 0 312.924V1277Z" fill="url(%23paint0_linear_1452_5852)"/><defs><linearGradient id="paint0_linear_1452_5852" x1="720" y1="0" x2="720" y2="1315.53" gradientUnits="userSpaceOnUse"><stop stop-color="%23E9EBF8"/><stop offset="1" stop-color="%231631DD" stop-opacity="0"/></linearGradient></defs></svg>');
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;

  @media screen and (max-width: 1024px) {
    height: 1003.47px;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="360" height="1004" viewBox="0 0 360 1004" fill="none" preserveAspectRatio="none"><path d="M0 1004C115.692 960.755 235.201 1017.34 360 993.879V17.4994C233.379 1.47714 110.165 -14.323 0 26V1004Z" fill="url(%23paint0_linear_1460_6277)"/><defs><linearGradient id="paint0_linear_1460_6277" x1="394.903" y1="-8.00016" x2="394.903" y2="1164.64" gradientUnits="userSpaceOnUse"><stop stop-color="%23E9EBF8"/><stop offset="1" stop-color="%231631DD" stop-opacity="0"/></linearGradient></defs></svg>');
    margin-bottom: 143px;
  }
  .third-section_wrapper {
    max-width: 904px;
    width: 100%;
    margin: 0 auto;
    padding-top: 110px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    @media screen and (max-width: 1024px) {
      padding-top: 35px;
    }

    h2 {
      font: 700 50px/50px "Anonymous Pro";
      color: $main-text;
      margin-bottom: 69px;
      @media screen and (max-width: 1024px) {
        font: 700 24px/24px "Anonymous Pro";
        margin-bottom: 30px;
        padding-left: 22px;
      }
    }
    .third-section__text_wrapper {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      row-gap: 48px;
      margin-bottom: 154px;
      @media screen and (max-width: 1024px) {
        row-gap: 32px;
        margin-bottom: 70px;
        padding-left: 22px;
        padding-right: 24px;
      }

      .third-section__text {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        row-gap: 25px;
        @media screen and (max-width: 1024px) {
          row-gap: 15px;
          padding-left: 26px;
        }

        h3 {
          font: 700 30px/36px "Anonymous Pro";
          color: $main-text;
          letter-spacing: 1px;
          @media screen and (max-width: 1024px) {
            font: 700 20px/24px "Anonymous Pro";
          }
        }
        p {
          font: 700 24px/29px "Anonymous Pro";
          color: $main-text;
          letter-spacing: 0.7px;
          position: relative;
          @media screen and (max-width: 1024px) {
            font: 700 16px/19.2px "Anonymous Pro";
            letter-spacing: unset;
          }
          &::before {
            content: url("../../../../assets/svg/snow.svg");
            position: absolute;
            top: 9%;
            left: -76px;
            width: auto;
            height: auto;
            @media screen and (max-width: 1024px) {
              content: url("../../../../assets/svg/snow-mobile.svg");
              top: 4%;
              left: -35px;
            }
          }
        }
      }
    }
  }
}
