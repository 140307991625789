main {
  width: 100%;
  height: 100%;
  overflow: hidden;

  svg {
    width: 100%;
    height: 100%;
    path {
      width: 100%;
    }
  }
}
