@import "../../../../Shared/colors";

.fourth-section__popap {
  background-color: $main-text;
  padding: 40px 40px 21px 40px;
  border-radius: 10px;
  @media screen and (max-width: 1024px) {
    padding: 40px 20px 40px 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .fourth-section__popap_title {
    width: 615px;
    height: 99px;
    background-color: $main-yellow;
    margin: 0 auto;
    margin-bottom: 28px;
    border-radius: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 4%;
    @media screen and (max-width: 1024px) {
      width: 320px;
      height: 97px;
      padding: 19px 10px 20px 15px;
      border-radius: 10px;
      margin-bottom: 30px;
    }

    h2 {
      font: 700 24px/28.8px "Anonymous Pro";
      color: $main-text;
      @media screen and (max-width: 1024px) {
        font: 700 18px/21px "Anonymous Pro";
      }
    }
  }
  .fourth-section__popap_form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    .form-items_data {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      row-gap: 24px;
      margin-bottom: 32px;
      @media screen and (max-width: 1024px) {
        margin-bottom: 32px;
      }
      .form-item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-column-gap: 10px;
        -moz-column-gap: 10px;
        column-gap: 10px;
        border-bottom: 1px solid $form-border-bottom;

        @media screen and (max-width: 1024px) {
          -webkit-column-gap: 5px;
          -moz-column-gap: 5px;
          column-gap: 5px;
        }
        label {
          font: 700 24px/28.8px "Anonymous Pro";
          color: #dadada;
          white-space: nowrap;
          @media screen and (max-width: 1024px) {
            font: 700 18px/21.6px "Anonymous Pro";
          }
        }
        input {
          width: 100%;
          height: 29px;
          caret-color: #0094ff;
          font: 700 24px/28.8px "Anonymous Pro";
          color: $main-background;
          @media screen and (max-width: 1024px) {
            font: 700 16px/19.2px "Anonymous Pro";
            height: 22px;
          }
        }
      }
    }
    .form__radio-buttons__wrapper {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      row-gap: 15px;
      @media screen and (max-width: 1024px) {
        row-gap: 22px;
      }
      .form__radio-buttons {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-column-gap: 30px;
        -moz-column-gap: 30px;
        column-gap: 30px;

        input[type="radio"] {
          display: none;
        }
        label {
          display: inline-block;
          cursor: pointer;
          position: relative;
          padding-left: 60px;
          margin-right: 0;
          line-height: 18px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          font: 700 24px/28.8px "Anonymous Pro";
          color: #dadada;
          white-space: nowrap;
          @media screen and (max-width: 1024px) {
            font: 700 18px/21.6px "Anonymous Pro";
          }
        }
        label:before {
          content: "";
          display: inline-block;
          width: 30px;
          height: 30px;
          position: absolute;
          left: 0;
          bottom: 1px;
          background: url("../../../../assets//svg/radio-nonchecked.svg") 0 0
            no-repeat;
          @media screen and (max-width: 1024px) {
            background: url("../../../../assets//svg/radio-nonchecked-mobile.svg")
              0 0 no-repeat;
            width: 20px;
            height: 20px;
          }
        }
        input[type="radio"]:checked + label:before {
          background: url("../../../../assets//svg/radio-checked.svg") 0 0
            no-repeat;
          @media screen and (max-width: 1024px) {
            background: url("../../../../assets//svg/radio-checked-mobile.svg")
              0 0 no-repeat;
          }
        }
      }
    }
    .form__errors_button {
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      .error-message {
        font: 700 24px/28.8px "Anonymous Pro";
        color: $form-error;
        text-align: center;
        position: absolute;
        top: 20%;
        left: 0;
        right: 0;
        @media screen and (max-width: 1024px) {
          font: 700 16px/19.2px "Anonymous Pro";
          bottom: 28%;
        }
      }
    }

    .form__aprove_wrapper {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      -webkit-column-gap: 16px;
      -moz-column-gap: 16px;
      column-gap: 16px;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      @media screen and (max-width: 1024px) {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
      }

      .form__aprove_checkbox_wrapper {
        position: relative;
        input {
          position: absolute;
          width: 25px;
          height: 25px;
          opacity: 0;
          @media screen and (max-width: 1024px) {
            width: 15px;
            height: 15px;
          }
        }
        .form__aprove_checkbox {
          background-color: transparent;
          border: 4px solid $main-background;
          width: 25px;
          height: 25px;
          @media screen and (max-width: 1024px) {
            width: 15px;
            height: 15px;
            border-width: 2px;
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .form__aprove_label {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-column-gap: 5px;
        -moz-column-gap: 5px;
        column-gap: 5px;
        @media screen and (max-width: 1024px) {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
        }
        p {
          font: 700 18px/21.6px "Anonymous Pro";
          color: $main-background;
          @media screen and (max-width: 1024px) {
            font: 700 16px/19.2px "Anonymous Pro";
          }
        }
        a {
          font: 700 18px/21.6px "Anonymous Pro";
          color: #0094ff;
          border-bottom: 2px solid #0094ff;
          cursor: pointer;
          @media screen and (max-width: 1024px) {
            font: 700 16px/19.2px "Anonymous Pro";
          }
        }
      }
      .form__aprove_checkbox_wrapper.error .form__aprove_checkbox {
        border-color: $form-error;
      }
    }
  }
}

.finish__popap_wrapper {
  padding: 90px 98px 35px 90px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  @media screen and (max-width: 1024px) {
    padding: 100px 30px 100px 30px;
  }
  .finish__popap_smile {
    width: 63px;
    height: 67px;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 51px;
    @media screen and (max-width: 1024px) {
      width: 30px;
      height: 36px;
    }
  }
  .finish__popap_smile.employer {
    background-image: url("../../../../assets//svg/smile-green.svg");
    @media screen and (max-width: 1024px) {
      background-image: url("../../../../assets//svg/smile-green-mobile.svg");
    }
  }
  .finish__popap_smile.applicant {
    background-image: url("../../../../assets//svg/smile-yellow.svg");
    @media screen and (max-width: 1024px) {
      background-image: url("../../../../assets//svg/smile-yellow-mobile.svg");
    }
  }
  .finish__popap_text {
    width: 499px;
    height: 174px;
    margin-bottom: 43px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    @media screen and (max-width: 1024px) {
      width: 300px;
      height: 154px;
    }
    p {
      font: 700 24px/28.8px "Anonymous Pro";
      color: $main-background;
      @media screen and (max-width: 1024px) {
        font: 700 18px/21.6px "Anonymous Pro";
      }
    }
  }
}

.submit__button {
  width: 303px;
  height: 58px;
  background-color: $main-text-white;
  border-radius: 24px;
  border-color: #737373;
  border-style: solid;
  border-width: 7px 0px 0px 9px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  font: 700 30px/36px "Anonymous Pro";
  color: $main-text;
  margin-top: 97px;
  margin-bottom: 25px;
  @media screen and (max-width: 1024px) {
    width: 260px;
    height: 50px;
    border-width: 5px 0px 0px 7px;
    border-radius: 12px;
    font: 700 18px/21.6px "Anonymous Pro";
    margin-top: 65px;
    margin-bottom: 20px;
  }
  &:active {
    border-width: 3px 0px 0px 5px;
    background-color: #d3d3d3;
  }
}

.err-mess {
  font: 700 17px/16px "Anonymous Pro";
  color: $form-error;
  margin: -20px 0px;
}

.err-border {
  border-bottom: 1px solid $form-error !important;
}
