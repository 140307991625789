@import "../../../../Shared/colors";

.fourth-section {
  width: 100%;
  height: 1138px;
  margin-bottom: 170px;
  background-image: url('data:image/svg+xml;utf8,<svg width="1440" height="1138" viewBox="0 0 1440 1138" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"><path d="M0 1138C393.706 935.729 1100.47 1295.57 1366.78 998.489C1393.64 1010.92 1417.98 1020.01 1440 1026.05V174.111C1398.71 70.2321 1366.78 0 1366.78 0C1215.07 159.529 989.811 108.303 756.457 55.2363C479.106 -7.83592 190.32 -73.5082 0 208.981V1138Z" fill="url(%23paint0_linear_1452_6148)"/><defs><linearGradient id="paint0_linear_1452_6148" x1="720" y1="175.276" x2="720" y2="1288.03" gradientUnits="userSpaceOnUse"><stop stop-color="%23FFFB9A" stop-opacity="0.5"/><stop offset="0.842561" stop-color="%23FFEE96"/></linearGradient></defs></svg>');
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  @media screen and (max-width: 1024px) {
    background-image: url('data:image/svg+xml;utf8,<svg width="360" height="1008" viewBox="0 0 360 1008" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"><path d="M0 1008C115.335 956.804 234.818 998.02 360 975.721V26.4158C235.245 0.176288 111.936 -13.6436 0 19.3399V1008Z" fill="url(%23paint0_linear_1460_6276)"/><defs><linearGradient id="paint0_linear_1460_6276" x1="465" y1="-0.344957" x2="465" y2="1207.54" gradientUnits="userSpaceOnUse"><stop stop-color="%23FFFB9A" stop-opacity="0.5"/><stop offset="0.842561" stop-color="%23FFEE96"/></linearGradient></defs></svg>');
    height: 1008px;
    margin-bottom: 173px;
  }

  .fourth-section_wrapper {
    max-width: 904px;
    width: 100%;
    margin: 0 auto;
    padding-top: 150px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    @media screen and (max-width: 1024px) {
      padding-top: 18px;
    }

    h2 {
      font: 700 50px/50px "Anonymous Pro";
      color: $main-text;
      margin-bottom: 70px;
      @media screen and (max-width: 1024px) {
        font: 700 24px/24px "Anonymous Pro";
        margin-bottom: 30px;
        padding-left: 22px;
      }
    }
    .fourth-section__text_wrapper {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      row-gap: 48px;
      margin-bottom: 154px;
      @media screen and (max-width: 1024px) {
        row-gap: 29px;
        margin-bottom: 72px;
        padding-left: 22px;
        padding-right: 24px;
      }
      .fourth-section__text {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        row-gap: 25px;
        @media screen and (max-width: 1024px) {
          row-gap: 17px;
          padding-left: 26px;
        }
        h3 {
          font: 700 30px/36px "Anonymous Pro";
          color: $main-text;
          letter-spacing: 1px;
          @media screen and (max-width: 1024px) {
            font: 700 18px/21.6px "Anonymous Pro";
          }
        }
        p {
          font: 700 24px/29px "Anonymous Pro";
          color: $main-text;
          letter-spacing: 0.7px;
          position: relative;
          @media screen and (max-width: 1024px) {
            font: 700 16px/19.2px "Anonymous Pro";
          }
          &::before {
            content: url("../../../../assets/svg/snow-green.svg");
            position: absolute;
            top: 11%;
            left: -76px;
            width: auto;
            height: auto;
            @media screen and (max-width: 1024px) {
              content: url("../../../../assets/svg/snow-green-mobile.svg");
              top: 3%;
              left: -35px;
            }
          }
        }
      }
    }
  }
}
