@import "../../Shared/colors";

.docs__main {
  max-width: 1440px;
  width: 100%;
  background-color: $main-background;
  margin: 0 auto;

  .docs__wrapper {
    max-width: 1310px;
    width: 100%;
    margin: 0 auto;
    padding: 30px 60px 42px 60px;
    position: relative;
    @media screen and (max-width: 1024px) {
      padding: 25px;
    }
    .docs-close__wrapper {
      position: absolute;
      right: 43px;
      top: 35px;
      @media screen and (max-width: 1024px) {
        right: 18px;
        top: 18px;
      }
      .docs-close {
        position: fixed;
        width: 34px;
        height: 34px;
        border: 0;
        background-color: unset;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        cursor: pointer;
        @media screen and (max-width: 1024px) {
          width: 14px;
          height: 14px;
        }
        span {
          position: absolute;
          border: 3px solid #5e5e5e;
          width: 34px;
          background-color: #5e5e5e;
          @media screen and (max-width: 1024px) {
            width: 14px;
            border-width: 1px;
          }
          &:first-child {
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
          }
          &:last-child {
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
          }
        }
      }
    }

    h1 {
      font: 700 60px/72px "Inter";
      font-family: "Inter", sans-serif;
      color: $main-text;
      margin-bottom: 25px;
      @media screen and (max-width: 1024px) {
        font: 700 30px/36px "Inter";
        margin-bottom: 27px;
      }
    }
    .logo_link {
      position: relative;
      @media screen and (max-width: 1024px) {
      }
      &::after {
        content: "";
        position: absolute;
        bottom: 3px;
        left: 0;
        width: 100%;
        height: 5px;
        background-color: $title_cross;
        @media screen and (max-width: 1024px) {
          height: 3px;
        }
      }
    }
    h2 {
      font: 700 40px/48px "Inter";
      font-family: "Inter", sans-serif;
      color: $main-text;
      margin-bottom: 30px;
      @media screen and (max-width: 1024px) {
        font: 700 20px/24px "Inter";
        margin-bottom: 20px;
      }
    }
    h3 {
      font: 700 24px/28.8px "Inter";
      font-family: "Inter", sans-serif;
      color: $main-text;
      @media screen and (max-width: 1024px) {
        font: 700 18px/21.6px "Inter";
      }
    }
    h4 {
      font: 400 18px/21.6px "Inter";
      font-family: "Inter", sans-serif;
      color: $main-text;
      @media screen and (max-width: 1024px) {
        font: 400 14px/16.8px "Inter";
      }
      .span_link {
        color: $doc-link;
        cursor: pointer;
      }
      .span_link-cross {
        border-bottom: 1px solid $main-text;
      }
    }
    h5 {
      font: 400 18px/21.6px "Inter";
      font-family: "Inter", sans-serif;
      color: $main-text;
      text-align: center;
    }
    .docs__content {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      row-gap: 50px;
      margin-bottom: 57px;
      @media screen and (max-width: 1024px) {
        row-gap: 30px;
        margin-bottom: 80px;
      }
      .docs__section {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        row-gap: 30px;
        @media screen and (max-width: 1024px) {
          row-gap: 20px;
        }
      }
    }
  }
}
